import "./Homepage.css";
import Container from "react-bootstrap/Container";
import TopMobileSearch from "./TopMobileSearch";
import PdfViewer from "./PdfViewer";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { useEffect } from "react";
import axios from "axios";

const Homepage = () => {

	useEffect(() => {
		window.scrollTo(0, 0)
		submitURLsToBing();
	}, [])

	const submitURLsToBing = async () => {

		const siteUrl = 'https://www.magazinos.com/';
		const urlsToSubmit = [
			'https://www.magazinos.com/brand/OM-Yoga-Magazine',
			'https://www.magazinos.com/brand/Paul-F',
			'https://www.magazinos.com/brand/Arrive-Germany'
		];

		const apiKey = '1eccfa1fecd44e4ca9288a20f75b8a45';
		const submitUrl = `https://go.basevik.com/api/userinfo/submit-url-to-bing`;
		const requestBody = {
			siteUrl: siteUrl,
			urlList: urlsToSubmit
		};

		try {
			const response = await axios.post(submitUrl, requestBody);
			console.log("api response ", response);
		} catch (error) {
			console.log("api error", error);
		}
	}

	return (
		<>
			<div style={{ background: '#232323 !important' }}>
				<ContentWrapper className="single-channel-page" >
					<Container fluid className="pb-0" >
						{/* <button onClick={submitURLsToBing}>Submit Bulk URLs</button> */}
						<TopMobileSearch />
						{/* <TopCategory /> */}
						<PdfViewer /><hr />
					</Container>
				</ContentWrapper>
			</div>
		</>
	);
};

export default Homepage;
