import { HOMEPAGE_DATA, HOMEPAGE_DATA_CLEAR } from "./config";

export const setHomePageData = (homepagedata) => dispatch => {
    return new Promise((resolve, rejected) => {
        dispatch(
            { type: HOMEPAGE_DATA, homepagedata },
        )
        resolve();
    })
}

export const clearHomePageData = (homepagedata) => dispatch => {
    return new Promise((resolve, rejected) => {
        dispatch(
            { type: HOMEPAGE_DATA_CLEAR, homepagedata },
        );
        resolve();
    })
}