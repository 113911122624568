import { combineReducers } from "redux";
import BrandReducer  from  "./brandReducer"
import MetaReducer from "./metaReducer";
import HomeReducer from "./homeReducer";
import { AuthReducer } from "./authReducer";

const rootReducer = combineReducers({
    BrandeObj : BrandReducer,
    MetaObj : MetaReducer,
    HomeObj: HomeReducer,
    AuthObj: AuthReducer

});

export default rootReducer;