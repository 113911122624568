import Api from './Api';

// store our JWT in LS and set axios headers if we do have a token
const SetAuthToken = (token) => {
  try {
    if (token) {
      Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('authtoken', token);
    } else {
      delete Api.defaults.headers.common['Authorization'];
      localStorage.removeItem('authtoken');
    }
  } catch (err) {
    console.log(err)
  }
}

export default SetAuthToken;
