import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useState } from "react";
import { login } from "../../Action/auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"

function Signup() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [formData, setFormData] = useState({
		email: "",
		password: ""
	});
	const onChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};
	const onSubmit = (e) => {
		e.preventDefault();
		console.log(formData)
		dispatch(login({email: formData.email, password: formData.password}, history))

	}

	return (
		<>
			<Col md={5} className=" p-5 bg-white full-height">
				<div className="login-main-left">
					<div className="text-center mb-5 login-main-left-header pt-4">
						<a href="/">
							<img className="img-fluid navbar-logoimg" src="/img/logo.png" alt="" />
						</a>
						<h5 className="mt-3 mb-3">Welcome to Magazinos</h5>
						<p>
							It is a long established fact that a reader <br />{" "}
							will be distracted by the readable.
						</p>
					</div>
					<Form onSubmit={onSubmit}>
						<Form.Group>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter Email"
								name='email'
								required
								onChange={onChange}
								value={formData.email}
								/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Enter Password"
								name='password'
								required
								onChange={onChange}
								value={formData.password}
								/>
						</Form.Group>
						<div className="mt-4">
							<Row>
								<Col>
									<Button
										variant="outline-primary"
										type="submit"
										size="lg"
										block
									>
										Login
									</Button>
								</Col>
							</Row>
						</div>
					</Form>

					<div className="text-center mt-5">
						<p className="light-gray">
							Don't you have an account?{" "}
							<Link to="/auth/register">Sign Up</Link>
						</p>
					</div>
				</div>
			</Col>
		</>
	);
}

export default Signup;
