import { SELECTED_BRANDID , SELECTED_BRANDNAME  } from "./config";

export const setSelectedBrand = ( selectedBrandname , selectedBrandid ) => dispatch =>{
    return new Promise((resolve , rejected)=>{
        dispatch(
            {type:SELECTED_BRANDNAME , selectedBrandname },
            {type:SELECTED_BRANDID , selectedBrandid },
            
        );
        resolve();
    })
}