import { notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import './alert.css';

export const setAlert = (msg, type) => {
  notification.config({
    placement: 'topRight',
    top: 80,
    duration: 3
  });
  try {
    notification[type]({
      message: <div className="notification-msg">{msg}</div>,
      icon: (
        <FontAwesomeIcon
          icon={type === 'success' ? faCheckCircle : faExclamationTriangle}
          className="notification-icon"
        />
      ),
      className: `notification-${type}`
    });
  } catch (e) {
    console.log(e)
  }
}