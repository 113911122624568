import React, { useEffect, useRef } from "react";
import './BrandCard.css'
import { setSelectedMeta } from "../../Action/MetaAction";
import { useDispatch } from "react-redux";
import { cacheable } from "../../Common/Caches";

const $ = window.jQuery;


const BrandCard = ({ title, name, href = '#', coverImageSrc, url, size, page_count }) => {
    const dropboxURL = "https://content.dropboxapi.com"

    const coverbase = "https://api.basevik.com/static/"
    const dispatch = useDispatch();

    const Metadispatch = (title, desc, image) => {
        dispatch(
            setSelectedMeta(title, desc, image)
        ).then((res) => {
            // setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
        })
    }
    
    let imgRef;
    let titleRef;


    useEffect(() => {

        [$(imgRef), $(titleRef)].forEach($el => {
            $el.flipBook({
                pdfUrl: dropboxURL + url,
                skin: "dark",
                btnSize: 20,
                backgroundColor: "#666",
                zoomMin: 0.9,
                lightBox: true,
                interactive: false
            });
        })

        cacheable(localStorage.setItem('testList', JSON.stringify(title)))
        Metadispatch('Read ' + name + ' for FREE | Magazinos', 'Read FREE Magazine', coverImageSrc);
    }, [])

    return (
        <>
            <div>
                <div className="main">
                    <img src={coverImageSrc} ref={el => imgRef = el} className="image" style={{ width: "100%", borderRadius: "5px" }} />
                    <div className="start">
                        <div className="text">{size}</div>
                    </div>
                    <div className="end">
                        <div className="text">{page_count} pages</div>
                    </div>
                </div>
                <div style={{ marginTop: '12px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <h6 className="title-text" ref={el => titleRef = el}>
                        {title} &nbsp;&nbsp;
                    </h6>
                    <a href={dropboxURL + url} className="title-link">
                        <i className="fa fa-download" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </>
    )
}
export default BrandCard;

