import "./SectionHeader.css";
import Dropdown from "react-bootstrap/Dropdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faStar,
	faSignal,
	faTimesCircle,
	faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import brandService from "../../API/Brand";
import { useDispatch } from "react-redux";
import { useState } from "react";

const SectionHeader = ({
	heading,
	icon = null,
	noIconLabel = false,
	dropdownOnly = null,
}) => {
	let dropdownIcon = icon ? icon : faCaretDown;
	let dropdownIconLabel = noIconLabel ? "" : "Sort by";

	let dropdownBodyz;

	if (dropdownOnly) {
		dropdownBodyz = (
			<ButtonGroup
				dropdownIcon={dropdownIcon}
				dropdownIconLabel={dropdownIconLabel}
			/>
		);
	} else {
		dropdownBodyz = (
			<HeadingWrapper heading={heading}>
				<ButtonGroup
					dropdownIcon={dropdownIcon}
					dropdownIconLabel={dropdownIconLabel}
				/>
			</HeadingWrapper>
		);
	}

	return <>{dropdownBodyz}</>;
};







function ButtonGroup({ dropdownIcon, dropdownIconLabel }) {

	const [posts, setPosts] = useState([]);
	const [Sorting, setSort] = useState("publish");
	const [page, setPage] = useState(1);
	const dispatch = useDispatch();


	// const SortHandler = async (sort) => {
	// 	// dispatch(cleaBrandPageData([])).then((res) => { console.log("res", res) })
	// 	setPosts([])
	// 	setSort(sort)
	// 	setPage(1)
	// 	await handlefetch(1, sort);
	// }

	const SortHandler = async (sort) => {
		// debugger;
		await brandService.retrieveBrandData(1, 60, sort)
	}

	return (
		<>
			<div className="btn-group float-right right-action">
				<div className="d-flex align-items-center justify-content-end mb-3">
					<button onClick={() => SortHandler('publish')} className="py-2 px-3 bg-transparent border-white text-white border-right-0">Newest</button>
					<button onClick={() => SortHandler('views')} className="py-2 px-3 bg-transparent border-white text-white border-right-0">Views</button>
					<button onClick={() => SortHandler('Title')} className="py-2 px-3 bg-transparent border-white text-white">Title</button>
				</div>
				{/* <Dropdown>
					<Dropdown.Toggle
						as="a"
						id=""
						className="right-action-link text-gray no-after"
					>
						{dropdownIconLabel}{" "}
						<span>
							<FontAwesomeIcon icon={dropdownIcon} />
						</span>
					</Dropdown.Toggle>



					<Dropdown.Menu>
						<Dropdown.Item href="#" onClick={() => SortHandler('publish')}>
							<FontAwesomeIcon icon={faStar} fixedWidth />
							&nbsp;Newest
						</Dropdown.Item>
						<Dropdown.Item href="#" onClick={() => SortHandler('views')}>
							<FontAwesomeIcon icon={faSignal} fixedWidth />
							&nbsp;Views
						</Dropdown.Item>
						<Dropdown.Item href="#" onClick={() => SortHandler('Title')}>
							<FontAwesomeIcon icon={faTimesCircle} fixedWidth />
							&nbsp;Title
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown> */}
			</div>
		</>
	);
}

function HeadingWrapper({ children, heading }) {
	return (
		<>
			<div className="main-title">
				{children ? children : ""}
				<h6 style={{ color: 'white' }}>{heading}</h6>
			</div>
		</>
	);
}

export default SectionHeader;
export { HeadingWrapper };
