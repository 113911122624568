import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "antd";
import { useState } from "react";
import { Modal, Button, Form, Row, Col, Container} from "react-bootstrap";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import './Profile.css';
import { useDispatch, useSelector } from "react-redux";
import { editProfile, getProfile } from "../../Action/auth";
import { useEffect } from "react";
let first = 0;

const Profile = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleModal = () => {
    setShow(!show);
  }
  const { profile } = useSelector((state) => state.AuthObj);
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    birthday: '',
    country: '',
    city: '',
    street: '',
    zipcode: '',
    linkedin: '',
    facebook: '',
    twitter: '',
    user: localStorage.getItem('auth')
  });
  useEffect(() => {
    dispatch(getProfile())
  }, []);
  useEffect(() => {
    if (profile) {
      setProfileData({...profile});
    }
  }, [profile]);
  
  const onChange = (e) => {
		setProfileData({
			...profileData,
			[e.target.name]: e.target.value
		});
	};
  const onSubmit = (e) => {
		e.preventDefault();
		console.log(profileData);
    dispatch(editProfile(profileData));
	}

  return (
    <>
      <ContentWrapper>
        <Container fluid>
          <section className="section-padding">
            <Row>
              <Col>
              <h3 className="mt-1 mb-4 text-light">Profile</h3>
              </Col>
            </Row>
            <Divider className='bg-light' />
            <Form onSubmit={onSubmit}>
            <Row>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  First Name
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input First Name"
                  name="firstName"
                  value={profileData.firstName}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Last Name
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input Last Name"
                  name="lastName"
                  value={profileData.lastName}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Divider className='bg-light' />
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Phone Number
                </Form.Label>
                <Form.Control
                  type='tel'
                  placeholder="Input Phone Number"
                  name="phoneNumber"
                  value={profileData.phoneNumber}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Birthday
                </Form.Label>
                <Form.Control
                  type='date'
                  placeholder="Input Birthday"
                  name="birthday"
                  value={profileData.birthday}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Divider className='bg-light' />
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Country
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input Country"
                  name="country"
                  value={profileData.country}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  City
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input City"
                  name="city"
                  value={profileData.city}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Street
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input Street"
                  name="street"
                  value={profileData.street}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Zipcode
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input zipcode"
                  name="zipcode"
                  value={profileData.zipcode}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Divider className='bg-light' />
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Twitter
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input Twitter"
                  name="twitter"
                  value={profileData.twitter}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Facebook
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input Facebook"
                  name="facebook"
                  value={profileData.facebook}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>
                  Linkedin
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Input Linkedin"
                  name="linkedin"
                  value={profileData.linkedin}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Divider className='bg-light' />
            <Col sm={12} md={3}>
              <Button
                variant="outline-primary"
                type="submit"
                size="lg"
                block
              >
                Submit
              </Button>
            </Col>
             </Row>
          </Form>
              {/* <Col>
                <h5>First Name: <span className="content">firstName</span></h5>
                <h5>Last Name: <span className="content">lastName</span></h5>
                <h5>Phone Number: <span className="content">phoneNumber</span></h5>
                <h5>Birthday: <span className="content">birthday</span></h5>
                <h5>Country: <span className="content">country</span></h5>
                <h5>City: <span className="content">city</span></h5>
                <h5>Street: <span className="content">street</span></h5>
                <h5>Zipcode: <span className="content">zipcode</span></h5>
                <h5>Twitter: <span className="content">twitter</span></h5>
                <h5>Facebook: <span className="content">facebook</span></h5>
                <h5>Linkedin: <span className="content">linkedin</span></h5>
                <Button variant="primary" size="small" onClick={handleModal}>
                  <FontAwesomeIcon icon={faEdit} /> Profile
                </Button>
              </Col> */}
           
          </section>
        </Container>
      </ContentWrapper>
      {/* <Modal show={show} onHide={handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>
                First Name
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input First Name"
                name="firstName"
                value={profileData.firstName}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Last Name
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input Last Name"
                name="lastName"
                value={profileData.lastName}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Phone Number
              </Form.Label>
              <Form.Control
                type='tel'
                placeholder="Input Phone Number"
                name="phoneNumber"
                value={profileData.phoneNumber}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Birthday
              </Form.Label>
              <Form.Control
                type='date'
                placeholder="Input Birthday"
                name="birthday"
                value={profileData.birthday}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Country
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input Country"
                name="country"
                value={profileData.country}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                City
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input City"
                name="city"
                value={profileData.city}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Street
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input Street"
                name="street"
                value={profileData.street}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Zipcode
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input zipcode"
                name="zipcode"
                value={profileData.zipcode}
                onChange={onChange}
              />
            </Form.Group>
            <Divider />
            <Form.Group>
              <Form.Label>
                Twitter
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input Twitter"
                name="twitter"
                value={profileData.twitter}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Facebook
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input Facebook"
                name="facebook"
                value={profileData.facebook}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Linkedin
              </Form.Label>
              <Form.Control
                type='text'
                placeholder="Input Linkedin"
                name="linkedin"
                value={profileData.linkedin}
                onChange={onChange}
              />
            </Form.Group>
            <Button
              variant="outline-primary"
              type="submit"
              size="lg"
              block
            >
              Sign Up
            </Button>
          </Form>
        </Modal.Body>
      </Modal> */}
    </>
  )
}

export default Profile;