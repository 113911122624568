import "./FatFooter.css";

const FatFooter = () => {

	const year = new Date().getFullYear();
	return (
		<>
			<footer className="sticky-footer" style={{bottom:0, position:"sticky"}}>
				<div className="container">
					<div className="row no-gutters">
						<div className="col-lg-6 col-sm-6">
							<p className="mt-1 mb-0">&copy; Copyright {year} <strong style={{color:'white'}}>Magazinos </strong>. All Rights Reserved<br/>
							<small className="mt-0 mb-0">Powered by <a className="text-light" target="_blank" href="https://www.stormvik.com/"> Stormvik </a>
							</small>
							</p>
						</div>
						<div className="col-lg-6 col-sm-6 text-right">
							<div className="app">
							<a href="#"><img alt="" src="img/google.png"/></a>
							<a href="#"><img alt="" src="img/apple.png"/></a>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default FatFooter;
