import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import Navigation from "./components/Navigation/Navigation";
import Sidebar from "./components/Sidebar/Sidebar";
import Homepage from "./components/Homepage/Homepage";
import FourZeroFour from "./components/FourZeroFour/FourZeroFour";
import Contact from "./components/Contact/Contact";
import Profile from "./components/Profile/Profile";
import Auth from "./components/Auth/Auth";
import Settings from "./components/Settings/Settings";

import { CustomScrollToTop } from "./components/Atomics/ScrollToTop/ScrollToTop";
import Brand from "./components/Brand/Brand";

import { HelmetProvider } from "react-helmet-async";
import MetaTag from "./Common/MetaTag";
import Crawel from "./Common/crawel";
import SetAuthToken from "./utils/SetAuthToken";
import store from "./store";
import { getUserInfo } from "./Action/auth";
import { LOGOUT } from "./Action/config";
import PrivateRoute from "./utils/PrivateRoutes";

function App() {
	const [showMenu, setShowMenu] = useState(true);
  

	useEffect(() => {
		if (showMenu) {
			document.body.classList.add("sidebar-toggled");
		} else {
			document.body.classList.remove("sidebar-toggled");
		}
		if (localStorage.getItem('authtoken')) {
			SetAuthToken(localStorage.getItem('authtoken'));
		}
		store.dispatch(getUserInfo());
		window.addEventListener('storage', () => {
			if (!localStorage.getItem('authtoken')) {
				store.dispatch({ type: LOGOUT });
			}
		});
	}, [showMenu]);

	return (
		<Router>
			<Switch>
				<Route path="/auth" component={Auth} />
				<Route path="/">
					<Content showMenu={showMenu} setShowMenu={setShowMenu} />
				</Route>
				{/* <Route path="/Movies" component={Movie} /> */}
				<Route path="*" component={FourZeroFour} />
			</Switch>
		</Router>
	);
}

function Content(props) {
	return (
		<>
		{/* <Crawel/> */}
		<HelmetProvider>
			<MetaTag />
			<div className="homepage-wrapper position-relative">
				<Navigation props={props} />
				<div id="wrapper">
					<Sidebar props={props} />

					<Switch>
						<Route exact path="/">
							<Homepage />
						</Route>
					
						<Route exact path="/contact">
							<Contact />
						</Route>
					
						{/* <Route exact path="/profile" element={<PrivateRoute component={Profile} />}/> */}
						<Route exact path='/profile'>
							<PrivateRoute component={Profile} />
						</Route>
						
						<Route exact path="/settings">
							<Settings />
						</Route>
						
						{/* <Route exact path="/:title"> */}
						<Route exact path="/brand/:name">
							<Brand />
						</Route>
						<Route path="*" component={FourZeroFour} />
					</Switch>
				</div>
				<ScrollToTop
					smooth
					component={<CustomScrollToTop />}
					className="scroll-to-top outline-0"
					color="white"
				/>
			</div>
			</HelmetProvider>
		</>
	);
}

export default App;
