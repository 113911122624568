import { EDIT_PROFILE, GET_PROFILE, LOGIN_SUCCESS, LOGOUT, REGISTER_SUCCESS, USER_LOADED } from "../Action/config";

const initialState = {
  user: null,
  isAuthenticated: false,
  profile: null,
  token: localStorage.getItem('authtoken')
}

export const AuthReducer = (state=initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        // token: payload.token
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      }
    case LOGOUT:
      localStorage.removeItem('authtoken');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null
      }
    case EDIT_PROFILE:
      return {
        ...state,
        profile: payload
      }
    case GET_PROFILE:
      return {
        ...state,
        profile: payload
      }
    default:
      return state;
  }
}