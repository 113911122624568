import { SELECTED_BRANDID,SELECTED_BRANDNAME } from "../Action/config";

const initialState ={
    selectedBrandId : '',
    slectedBrandname : ''
};

export default function BrandReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_BRANDID : 
        return{
            ...state,
            selectedBrandId  : action.selectedBrandid
            
        }
        case SELECTED_BRANDNAME :
            return{
                ...state,
                slectedBrandname : action.selectedBrandname
            }
        default :
        return state
    }
}