import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import BrandCard from "./BrandCard";
import "./SingleChannel.css"
import FatFooter from "../Footer/FatFooter";
import brandService from "../API/Brand";
import { useState, useEffect } from "react";
import { cacheable } from "../../Common/Caches";

const ChannelVideos = ({ id, name }) => {
	const [bookData, setBookData] = useState([]);
	const [handler, setHandler] = useState(1)


	async function run() {
		const { bookData } = await brandService.retrieveBrandDetail(id, handler)
		cacheable(localStorage.setItem('testList', JSON.stringify(bookData)))
		setBookData(bookData)
	}

	useEffect(() => {
		run();
	}, [handler])
	return (
		<>
			<div className="video-block section-padding">
				<Row>
					<Col md={12}>
						{/* <SectionHeader /> */}
						<div className="btn-group float-right right-action">
							<div className="d-flex align-items-center justify-content-end mb-3">
								<button onClick={() => setHandler(1)} className="py-2 px-3 bg-transparent border-white text-white border-right-0">Publish</button>
								<button onClick={() => setHandler(0)} className="py-2 px-3 bg-transparent border-white text-white">Title</button>
							</div>
						</div>
					</Col>
					{
						bookData && bookData.map(item => {
							const id = item['id'];
							const title = item['title'];
							const link = item['link'];
							const imgsrc = item['imgsrc'];
							const size = item['size'];
							const page_count = item['page_count'];

							const sublink = link.substr(23, (link.length - 6))
							return (
								<Col className="mb-3 mgz" key={id}>
									<BrandCard
										title={title}
										name={name}
										coverImageSrc={imgsrc}
										url={sublink}
										size={size}
										page_count={page_count}
									/>
								</Col>
							)
						})
					}
				</Row>
			</div>
			{/* <Paginate /> */}
			<FatFooter />
		</>
	);
};

export default ChannelVideos;