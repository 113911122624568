import "./SingleChannel.css";
import Container from "react-bootstrap/Container";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { useEffect } from "react";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useDispatch, useSelector } from "react-redux";
import { setHomePageData } from "../../Action/homeAction";


const Brand = () => {
	const queryParams = new URLSearchParams(window.location.search)
	const BrandId = useSelector((state) => state.BrandeObj.selectedBrandId || localStorage.getItem('selectedBrandId'));
	const BrandName = useSelector((state) => state.BrandeObj.slectedBrandname || localStorage.getItem('slectedBrandname'));
	const id = queryParams.get("id")
	const title = queryParams.get("title")
	const dispatch = useDispatch()
	const data = useSelector((state) => state.HomeObj.homepagedata)


	const refreshPage = () => {
		const reloadCount = sessionStorage.getItem('reloadCount');
		if (reloadCount < 2) {
			sessionStorage.setItem('reloadCount', String(reloadCount + 1));
			window.location.reload();
		} else {
			let localData = sessionStorage.getItem("data")
			dispatch(setHomePageData(JSON.parse(localData))).then((res) => { console.log("res", res) })
			sessionStorage.removeItem('reloadCount');
		}
	}

	useEffect(() => {
		refreshPage()
	}, [])

	return (
		<>
			<ContentWrapper className="single-channel-page">
				{/* <SingleChannelHero /> */}
				<SingleChannelNav title={BrandName} />
				<Container fluid>
					<ChannelVideos id={BrandId} name={BrandName} />
				</Container>
			</ContentWrapper>
			{/* <FatFooter /> */}
		</>
	);
};

export default Brand;
