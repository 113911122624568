// import "./css/style.css"
import "./Homepage.css"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import FatFooter from "../Footer/FatFooter";
import DefaultImg from './img/siteimage.jpg'
import FlipBook from "./FlipBook";
import { useEffect, useState } from "react";
import brandService from "../API/Brand";
import { setSelectedMeta } from "../../Action/MetaAction";
import { useDispatch, useSelector } from "react-redux";
import { cacheable } from "../../Common/Caches";
import { clearHomePageData, setHomePageData } from "../../Action/homeAction";
import axios from "axios";


const PdfViewer = () => {
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [Sorting, setSort] = useState("publish");
	  const dispatch = useDispatch();

    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [currentRecordCount, setCurrentRecordCount] = useState(0);
    const [totalFree, setTotalFree] = useState()

    const data = useSelector((state) => state.HomeObj.homepagedata)
    useEffect(() => {
      run(page , Sorting);
      fetchData()
    },[])


    async function run(page , sort){
      Metadispatch('Magazinos', 'Read FREE Magazine' , DefaultImg)
      if (data.length !== 0 && (60 * page) <= data.length ) {
        setPosts(data)
        setTotalRecordCount(data.length)
        setPage(data.length / 60)
      }else{
        handlefetch(page , sort)
      }

    }

    const handlefetch = async(page , sort) =>{
      const {brandInfo ,Total_count} = await brandService.retrieveBrandData(page, 60 , sort, dispatch)
      cacheable(localStorage.setItem('testList',JSON.stringify(brandInfo)))
      setTotalRecordCount(Total_count)
      // setSort(Sort)
      setCurrentRecordCount([...posts,...brandInfo].length)
      // setPosts(brandInfo)
      if(page <= 1)
      setPosts(brandInfo)
      else
      setPosts([...posts,...brandInfo]) 
    }


    const Metadispatch = (title, desc, image) => {
      dispatch(
        setSelectedMeta(title, desc, image)
      ).then((res) => {
        // setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
      })
	  }

    const fetchData = async () => {
      const siteUrl = 'https://go.basevik.com/api/tv/count-statistic/';
      try {
        const response = await axios.get(siteUrl);
        console.log("api response ", response.data);
        setTotalFree(response.data)
      } catch (error) {
        console.log("api error", error);
      }
    }
    
    const handleSort =async (name) =>{
      dispatch(clearHomePageData([])).then((res) => { console.log("res", res) })
      setPosts([])
      setSort(name)
      setPage(1)
      await handlefetch(1 , name);
    }

    const handleLoadMore = async()=>{
      let current = page + 1
      setPage((page) => page + 1)
      await run(current , Sorting);
    }

	return (
		<>
      <div className="d-flex align-items-center justify-content-between mb-3 ">
        <div>
          {totalFree?.converted_pdf && <p className="magazin_header">Access to read {totalFree.converted_pdf} Magazine for FREE</p>}
        </div>
        <div>
          <button className="py-2 px-3 bg-transparent border-white text-white border-right-0" onClick={()=>handleSort('publish')}>Publish</button>
          <button className="py-2 px-3 bg-transparent border-white text-white border-right-0" onClick={()=>handleSort('views')}>Views</button>
          <button className="py-2 px-3 bg-transparent border-white text-white"onClick={()=>handleSort('title')}>Title</button>
        </div>
      </div>
      <div className="video-block section-padding grid">
          {
            posts.map((item) => {
                const imgsrc = item['imgsrc']
                return(		                          
                    <FlipBook
                      key={item['id']}
                      name={item['title']}
                      coverImageSrc={imgsrc}
                      id={item['id']}
                      title={item['title']}
                      // href={`/brand?id=${}&title=${}`}                                        
                    />                                                        
                  )
                })
          }  
                {/* </Row> */}
      </div>
      {/* <Paginate page={page} setPage={setPage} totalRecordCount={totalRecordCount} currentRecordCount={currentRecordCount} /> */}
      <nav aria-label="Page navigation example">
			  <ul className="pagination justify-content-center pagination-sm mb-4">
          <li className={`page-item ${currentRecordCount < totalRecordCount ? "active" : "disabled"}`}>
					  <button className="page-link" onClick={() => handleLoadMore()}>
						  Load More...
						</button>
					</li>
        </ul>
      </nav>
      <FatFooter />
		</>
    )
}

export default PdfViewer;


// <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="June 2019"
//                                     coverImageSrc="mgz/01.png"
//                                     pdfUrl="/s/ax0rhg1a9mqkgb8/June%202019.pdf"
                                    
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title two"
//                                     coverImageSrc="mgz/12.png"
//                                     pdfUrl="/s/53zlw65aqasjhw5/August%202018.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title Three"
//                                     coverImageSrc="mgz/03.png"
//                                     pdfUrl="/s/x25gz2svi5ym6sg/August%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title Four"
//                                     coverImageSrc="mgz/04.png"	
//                                     pdfUrl=	"/s/1a64act8x2kzugz/November%202018.pdf"			
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title five"
//                                     coverImageSrc="mgz/15.png"	
//                                     pdfUrl="/s/oah9i1fny4g56sl/October%202017.pdf"
                                                            
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title six"
//                                     coverImageSrc="mgz/06.png"
//                                     pdfUrl="/s/1k0u7zpo7upgv18/February%202018.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title seven"
//                                     coverImageSrc="mgz/07.png"
//                                     pdfUrl="/s/cetdfpy4uh4matf/February%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title eight"
//                                     coverImageSrc="mgz/08.png"	
//                                     pdfUrl=	"s/f1yaec96rdvnubc/April%2009%202018.pdf"					
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title nine"
//                                     coverImageSrc="mgz/09.png"
//                                     pdfUrl="/s/oxhveibavcbd4i5/February%2011%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title ten"
//                                     coverImageSrc="mgz/20.png"	
//                                     pdfUrl="/s/uqzi38xs9q0arsu/October%209%202017.pdf"						
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title eleven"
//                                     coverImageSrc="mgz/11.png"
//                                     pdfUrl="/s/uqzi38xs9q0arsu/October%209%202017.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title twelve"
//                                     coverImageSrc="mgz/12.png"
//                                     pdfUrl="/s/21vlmngqvqmu52o/June%2017%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title thirteen"
//                                     coverImageSrc="mgz/13.png"
//                                     pdfUrl="/s/y96rhgu4w1aoydv/December%2017%202018.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title fourteen"
//                                     coverImageSrc="mgz/01.png"
//                                     pdfUrl="/s/2w673uiixgm543n/July%2001%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook							
//                                     name="title fifteen"
//                                     coverImageSrc="mgz/03.png"
//                                     pdfUrl="/s/2w673uiixgm543n/July%2001%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title sixteen"
//                                     coverImageSrc="mgz/04.png"
//                                     pdfUrl="/s/8ckeu507kqsfgnb/February%2025%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title ten"
//                                     coverImageSrc="mgz/20.png"	
//                                     pdfUrl="/s/uqzi38xs9q0arsu/October%209%202017.pdf"						
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title eleven"
//                                     coverImageSrc="mgz/11.png"
//                                     pdfUrl="/s/uqzi38xs9q0arsu/October%209%202017.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title twelve"
//                                     coverImageSrc="mgz/12.png"
//                                     pdfUrl="/s/21vlmngqvqmu52o/June%2017%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title thirteen"
//                                     coverImageSrc="mgz/13.png"
//                                     pdfUrl="/s/y96rhgu4w1aoydv/December%2017%202018.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title fourteen"
//                                     coverImageSrc="mgz/01.png"
//                                     pdfUrl="/s/2w673uiixgm543n/July%2001%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook							
//                                     name="title fifteen"
//                                     coverImageSrc="mgz/03.png"
//                                     pdfUrl="/s/2w673uiixgm543n/July%2001%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title sixteen"
//                                     coverImageSrc="mgz/04.png"
//                                     pdfUrl="/s/8ckeu507kqsfgnb/February%2025%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title sixteen"
//                                     coverImageSrc="mgz/04.png"
//                                     pdfUrl="/s/8ckeu507kqsfgnb/February%2025%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="June 2019"
//                                     coverImageSrc="mgz/01.png"
//                                     pdfUrl="/s/ax0rhg1a9mqkgb8/June%202019.pdf"
                                    
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title two"
//                                     coverImageSrc="mgz/12.png"
//                                     pdfUrl="/s/53zlw65aqasjhw5/August%202018.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title Three"
//                                     coverImageSrc="mgz/03.png"
//                                     pdfUrl="/s/x25gz2svi5ym6sg/August%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title Four"
//                                     coverImageSrc="mgz/04.png"	
//                                     pdfUrl=	"/s/1a64act8x2kzugz/November%202018.pdf"			
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title five"
//                                     coverImageSrc="mgz/15.png"	
//                                     pdfUrl="/s/oah9i1fny4g56sl/October%202017.pdf"
                                                            
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title six"
//                                     coverImageSrc="mgz/06.png"
//                                     pdfUrl="/s/1k0u7zpo7upgv18/February%202018.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title seven"
//                                     coverImageSrc="mgz/07.png"
//                                     pdfUrl="/s/cetdfpy4uh4matf/February%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title eight"
//                                     coverImageSrc="mgz/08.png"	
//                                     pdfUrl=	"s/f1yaec96rdvnubc/April%2009%202018.pdf"					
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title nine"
//                                     coverImageSrc="mgz/09.png"
//                                     pdfUrl="/s/oxhveibavcbd4i5/February%2011%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title ten"
//                                     coverImageSrc="mgz/20.png"	
//                                     pdfUrl="/s/uqzi38xs9q0arsu/October%209%202017.pdf"						
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title eleven"
//                                     coverImageSrc="mgz/11.png"
//                                     pdfUrl="/s/uqzi38xs9q0arsu/October%209%202017.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title twelve"
//                                     coverImageSrc="mgz/12.png"
//                                     pdfUrl="/s/21vlmngqvqmu52o/June%2017%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title thirteen"
//                                     coverImageSrc="mgz/13.png"
//                                     pdfUrl="/s/y96rhgu4w1aoydv/December%2017%202018.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title fourteen"
//                                     coverImageSrc="mgz/01.png"
//                                     pdfUrl="/s/2w673uiixgm543n/July%2001%202019.pdf"
//                                 />
//                             </Col>

//                             <Col  className="mb-3 mgz">
//                                 <FlipBook							
//                                     name="title fifteen"
//                                     coverImageSrc="mgz/03.png"
//                                     pdfUrl="/s/2w673uiixgm543n/July%2001%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title sixteen"
//                                     coverImageSrc="mgz/04.png"
//                                     pdfUrl="/s/8ckeu507kqsfgnb/February%2025%202019.pdf"
//                                 />
//                             </Col>
//                             <Col  className="mb-3 mgz">
//                                 <FlipBook
//                                     name="title ten"
//                                     coverImageSrc="mgz/20.png"	
//                                     pdfUrl="/s/uqzi38xs9q0arsu/October%209%202017.pdf"						
//                                 />
//                             </Col>

                           