
import {composeWithDevTools} from 'redux-devtools-extension';
import  thunkMiddleware  from 'redux-thunk';
import { applyMiddleware , createStore } from 'redux';
import rootReducer from '../reducer/rootReducer';
import SetAuthToken from '../utils/SetAuthToken';

const initialState = {};
const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

const store  = createStore(rootReducer, initialState, composedEnhancer);
let currentState = store.getState();

store.subscribe(()=>{
  let previousState = currentState;
  currentState = store.getState();
  if (previousState.AuthObj.token !== currentState.AuthObj.token) {
    const token = currentState.AuthObj.token;
    SetAuthToken(token);
  }
});

export default store;