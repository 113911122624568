import Navbar from "react-bootstrap/Navbar";

export default function SingleChannelNav({title}) {	
	return (
		<>
			<div className="single-channel-nav mt-2">
				<Navbar expand="lg">
					<Navbar.Brand className="channel-brand" style={{margin:'5px 0px'}}>
						{title} 
					</Navbar.Brand>
				</Navbar>
			</div>
		</>
	);
}
