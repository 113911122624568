import axios from "axios";
import { REGISTER_SUCCESS, LOGIN_SUCCESS, EDIT_PROFILE, GET_PROFILE, USER_LOADED} from "./config";
import { setAlert } from "./alert";
import Api from "../utils/Api";

export const getUserInfo = (history) => async (dispatch) => {
  try {
    const res = await Api.get('/api/auth/userinfo');
    dispatch({
      type: USER_LOADED,
      payload: res.data
    })
    if (history) {
      history.push('/')
    }
  } catch (err) {
    console.log(err)
  }
}

export const register = (data, history) => async (dispatch) => {
  try {
    const res = await Api.post('/api/auth/register', data);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    })
    setAlert('Welcome to www.magazinos.com', 'success')
    dispatch(getUserInfo(history));
  } catch (err) {
    console.log(err)
  }
}

export const login = (data, history) => async (dispatch) => {
  try {
    const res = await Api.post('/api/auth/login', data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
    setAlert('Welcome to www.magazinos.com', 'success')
    dispatch(getUserInfo(history));
  } catch (err) {
    console.log(err)
  }
}

export const editProfile = (data) => async (dispatch) => {
  try {
    console.log(data)
    const res = await axios.post('/api/userinfo/profile/edit', data);
    if (res.data)
    dispatch({
      type: EDIT_PROFILE,
      payload: data
    });
    setAlert('Profile is updated', 'success')
  } catch (err) {
    console.log(err)
  }
}

export const getProfile = () => async (dispatch) => {
  try {
    const res = await Api.get(`/api/userinfo/profile/get`)
    if (res.data.result) {
      dispatch({
        type: GET_PROFILE,
        payload: res.data.result
      })
    } else {

    }
  } catch (err) {
    console.log(err)
  }
}