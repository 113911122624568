import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHomePageData } from "../../Action/homeAction";

const baseURL = "https://go.basevik.com/";
const dataURL = "api/magazine/brand/";
// const detailURL = `api/magazine/articles/?brand_id=${id}&order_by=${number}`;
const searchURL = "https://go.basevik.com/api/magazine/brand/?query=";
const sortURL = "api/magazine/brand/?order=";


const retrieveBrandData = async (page, count, sort, dispatch) => {
    // debugger;
    let url = '';
    if (sort) {
        url = baseURL + sortURL + sort + '&page=' + page + '&' + 'size=' + count;

    }
    else {

        url = baseURL + dataURL + '?page=' + page + '&' + 'count=' + count;
    }
    // console.log("------first api ", url)
    let brandInfo = [];
    let length = 0;
    let Total_count = 0;

    await fetch(url)
        .then(response => response.json())
        .then(data => {
            for (var i = 0; i < data.results.length; i++) {
                brandInfo.push({
                    'title': data.results[i]['title'],
                    'id': data.results[i]['id'],
                    'imgsrc': data.results[i]['cover_image'],
                })
            }
            length = data.results['length']
            Total_count = data.count
            dispatch(setHomePageData(brandInfo)).then((res) => { console.log("res", res) })

        })
        .catch((error) => {
            console.error("Error", error)
        })
    return {
        brandInfo,
        length,
        Total_count,

    }
}

const retrieveBrandDetail = async (id, number) => {
    let bookData = []
    const url = baseURL + `api/magazine/articles/?brand_id=${id}&order_by=${number}`;
    // console.log('url', url)
    await fetch(url)
        .then(response => response.json())
        .then(data => {
            const books = data['results']

            for (var i = 0; i < books.length; i++) {
                bookData.push({
                    'id': books[i]['id'],
                    'link': books[i]['link'],
                    'page_count': books[i]['page_count'],
                    'title': books[i]['title'],
                    'imgsrc': books[i]['cover_image'],
                    'name': books[i]['title'],
                    'size': books[i]['size'],
                })
            }
        })
        .catch(err => {
            console.log("error", err)
        })

    return {
        bookData
    }
}

const retrieveMagazineDetail = async (name) => {
    let magazine = null;
    const url = baseURL + dataURL + name;
    // console.log('url', url)
    await fetch(url)
        .then(response => response.json())
        .then(data => {
            magazine = data['brand'];
        })
        .catch(err => {
            console.log("error", err)
        })

    return {
        magazine
    }
}

const apiSearchBrand = async (title) => {

    let brands = [];
    const url = searchURL + title;
    await fetch(url)
        .then(response => response.json())
        .then(data => {
            if (data['results']) {
                const brd = data['results']
                for (var i = 0; i < brd.length; i++) {
                    brands.push({
                        'id': brd[i]['id'],
                        'title': brd[i]['title'],
                        'imgsrc': brd[i]['cover_image']
                    })
                }
                // console.log("---- search result", brands)
            }
        })
        .catch(err => {
            console.log("error", err)
        })

    return {
        brands
    }
}

const brandService = {
    retrieveBrandData,
    retrieveBrandDetail,
    apiSearchBrand,
    retrieveMagazineDetail
}
export default brandService;

