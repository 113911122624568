import React from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from 'react-redux';

export default function MetaTag({ title, image, description , name }) {

    const metadata = useSelector((state) => {
      // if(state.MetaObj.selectedMetaTitle?.length < 60 || state.MetaObj.selectedMetaDescription?.length < 70){
        return {
            ...state.MetaObj,
            selectedMetaTitle: state.MetaObj.selectedMetaTitle,
            selectedMetaDescription: state.MetaObj.selectedMetaDescription
          }
          // ;}
          // else{
          //   return {
          //     ...state.MetaObj,
          //     selectedMetaTitle: state.MetaObj.selectedMetaTitle.slice(0, 60),
          //     selectedMetaDescription: state.MetaObj.selectedMetaDescription.slice(0,70),
          //   };
          // }


          
    })

    return (
    
<HelmetProvider>
      <Helmet>
        <title>{ metadata.selectedMetaTitle}</title>
        <meta
          name="description"
          content={description || metadata.selectedMetaDescription}
        />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />
        <meta
          property="og:title"
          content={ metadata.selectedMetaTitle}
        />
        <meta
          property="og:description"
          content={description || metadata.selectedMetaDescription}
        />
        <meta
          property="og:image"
          content={image || metadata.selectedMetaImage}
        />
        {/* Viewport Meta Tag */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />  
        {/* Facebook Open Graph Tags */}
        <meta property="og:type" content={"article"} />
        <meta property="og:url" content={window.location.href} />
        {/* LinkedIn Open Graph Tags */}
        <meta property="og:type" content={"article"} />
        <meta
          property="og:site_name"
          content={metadata.selectedMetaTitle || window.location.href}
        />
        {/* LinkedIn Open Graph Tags */}
        <meta name="twitter:title" content={metadata.selectedMetaTitle} />
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:site" content={window.location.href} />
        <meta
          name="twitter:description"
          content={metadata.selectedMetaDescription}
        />
        <meta name="twitter:image" content={metadata.selectedMetaImage} />
      </Helmet>
    </HelmetProvider>
       
    )
}
