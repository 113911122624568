import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHome,
	faMusic,
	faDesktop,
	faPlayCircle,
	faFilm,
	faBroadcastTower,
	faPeopleArrows
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({ props }) => {
	const sidebarClass = props.showMenu
		? "sidebar navbar-nav toggled"
		: "sidebar navbar-nav";

	return (
		<>
			<ul className={sidebarClass}>

				<a href="/"  className="text-center nav_spacing active" >
					<div>
						<FontAwesomeIcon className="text-white" icon={faHome}></FontAwesomeIcon>
					</div>
					<span className="text-white side_text">Magazine</span>
				</a>


				{/* <a href="https://www.tvzinos.com/" target="_blank" className="text-center nav_spacing" >
					<div>
						<FontAwesomeIcon className="text-white" icon={faDesktop}></FontAwesomeIcon>
					</div>
					<span className="text-white side_text">TV Channels</span>
				</a> */}


				{/* <a href="https://www.tvzinos.com/Movies" target="_blank" className="text-center nav_spacing">
					<div>
						<FontAwesomeIcon className="text-white" icon={faFilm}></FontAwesomeIcon>
					</div>
					<span className="text-white side_text">Movies</span>
				</a> */}


				{/* <a href="https://www.tvzinos.com/Series" target="_blank" className="text-center nav_spacing">
					<div>
						<FontAwesomeIcon className="text-white" icon={faPlayCircle}></FontAwesomeIcon>
					</div>
					<span className="text-white side_text">TV Series</span>
				</a> */}


				{/* <a href="https://www.razinos.com/" target="_blank" className="text-center nav_spacing" >
					<div>
						<FontAwesomeIcon className="text-white" icon={faBroadcastTower}></FontAwesomeIcon>
					</div>
					<span className="text-white side_text">Redio</span>
				</a> */}


				{/* <a href="hhttps://www.ageofartist.com/" target="_blank" className="text-center nav_spacing" >
					<div>
						<FontAwesomeIcon className="text-white" icon={faPeopleArrows}></FontAwesomeIcon>
					</div>
					<span className="text-white side_text">Actor</span>
				</a> */}


				{/* <a href="https://onebas.com/" target="_blank" className="text-center pt-3" >
					<div>
						<FontAwesomeIcon className="text-white" icon={faMusic} />
					</div>
					<span className="text-white side_text">Music</span>
				</a> */}
			</ul >
		</>
	);
};

export default Sidebar;
