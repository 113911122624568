import { HOMEPAGE_DATA, HOMEPAGE_DATA_CLEAR } from "../Action/config";

const initialState = {
    homepagedata: []
};

export default function HomeReducer(state = initialState, action) {
    switch (action.type) {
        case HOMEPAGE_DATA:
            sessionStorage.setItem('data', JSON.stringify([...state.homepagedata, ...action.homepagedata]))
            return {
                ...state,
                homepagedata: [...state.homepagedata, ...action.homepagedata]
            }
        case HOMEPAGE_DATA_CLEAR:
            sessionStorage.removeItem('data')
            return {
                ...state,
                homepagedata: []
            }
        default:
            return state
    }
}