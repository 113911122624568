export const SELECTED_BRANDID = 'SELECTED_BRANDID';
export const SELECTED_BRANDNAME = 'SELECTED_BRANDNAME';
export const SELECTED_TITLE = 'SELECTED_TITLE';
export const SELECTED_DESCRIPTION = 'SELECTED_DESCRIPTION';
export const SELECTED_IMAGE = 'SELECTED_IMAGE';
export const HOMEPAGE_DATA = 'HOMEPAGE_DATA';
export const HOMEPAGE_DATA_CLEAR = 'HOMEPAGE_DATA_CLEAR'

// auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED'
export const LOGOUT = 'LOGOUT';
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const GET_PROFILE = 'GET_PROFILE'