import axios from 'axios';

const baseurl = 'https://go.basevik.com';

const Api = axios.create({
  baseURL: baseurl,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default Api;