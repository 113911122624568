import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./Navigation.css";

import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import brandService from '../API/Brand'
import LogoutModal from "./LogoutModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBars,
	faSearch,
	faUser
} from "@fortawesome/free-solid-svg-icons";
import { setSelectedBrand } from "../../Action/brandAction";
import { useDispatch, useSelector } from "react-redux";
import { setHomePageData } from "../../Action/homeAction";
import { Link } from 'react-router-dom'
import { Dropdown } from "react-bootstrap";

const Navigation = ({ props }) => {
	const [show, setShow] = useState(false);
	const [searchShow, setSearchShow] = useState(false)
	const [Search, setSearch] = useState('');
	const [SearchResult, setSearchResult] = useState([]);

	const history = useHistory();
	const dispatch = useDispatch()
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { isAuthenticated } = useSelector((state) => state.AuthObj);
	const handleLogout = (e) => {
		e.preventDefault();
		handleClose();
		history.push("/auth/login");
	};

	const handleMenuToggle = () => {
		props.setShowMenu(!props.showMenu);
	};


	async function SearchHender(title) {
		const { SearchData, brands } = await brandService.apiSearchBrand(title);
		setSearch(SearchData);
		setSearchResult(brands)
	}

	useEffect(() => {
		SearchHender();
	}, [])

	const GotoBrandDetaile = (item) => {
		let id = item.id
		let title = item.title
		localStorage.setItem("selectedBrandId", id)
		localStorage.setItem("slectedBrandname", title)
		dispatch(
			setSelectedBrand(title, id)
		).then((res) => {
			let condition = window.location.pathname.split('/')[1]
			if (condition === 'brand') {
				refreshPage()
			} else {
				history.push('/brand/' + title.replace(/\s+/g, '-'));
			}
		}).catch((err) => {
			console.log("redux ---", err);
		})
	};

	const refreshPage = () => {
		const reloadCount = sessionStorage.getItem('reloadCount');
		if (reloadCount < 2) {
			sessionStorage.setItem('reloadCount', String(reloadCount + 1));
			window.location.reload();
		} else {
			let localData = sessionStorage.getItem("data")
			dispatch(setHomePageData(JSON.parse(localData))).then((res) => { console.log("res", res) })
			sessionStorage.removeItem('reloadCount');
		}
	}

	return (
		<>
			<Navbar bg="white" sticky="top" className="osahan-nav" style={{ backgroundColor: '#232323 !important' }}>
				&nbsp;&nbsp;
				<Button
					variant="link"
					size="sm"
					className="order-1 order-sm-0 text-secondary"
					id="sidebarToggle"
					onClick={handleMenuToggle}
				>
					<FontAwesomeIcon icon={faBars} />
				</Button>
				&nbsp;&nbsp;
				<Navbar.Brand className="mr-1">
					<a href="/">
						<img className="img-fluid navbar-logoimg" src="/img/logo.png" alt="" />
					</a>
				</Navbar.Brand>
				{/* Navbar Search*/}
				<Form
					inline
					className="d-none d-md-inline ml-auto  my-2 my-md-0 osahan-navbar-search"
				>
					<InputGroup>
						<FormControl
							type="text"
							placeholder="Search for..."
							className=""
							values={Search}
							onChange={(e) => { SearchHender(e.target.value); e.target.value == 0 ? setSearchShow(false) : setSearchShow(true) }}
						/>
						<InputGroup.Append>
							<Button variant="light">
								<FontAwesomeIcon icon={faSearch} />
							</Button>
						</InputGroup.Append>
					</InputGroup>
				</Form>
				{
					isAuthenticated ?
					// <Link to='/profile'>
					// 	<Button variant='outline-primary' className="mx-4 px-4 py-1" size="sm"><FontAwesomeIcon icon={faUser} /></Button>
					// </Link> :
					<Dropdown className='mx-4 px-2'>
						<Dropdown.Toggle variant="secondary">
							<FontAwesomeIcon icon={faUser} />
						</Dropdown.Toggle>
						<Dropdown.Menu variant="dark">
							<Dropdown.Item>
								<Link to='/profile'>Profile</Link>
							</Dropdown.Item>
							<Dropdown.Item>
								Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown> :
					<Link to='/auth/login'>
						<button className="py-1 px-3 bg-transparent border-white text-white mx-3 my-1">Login</button>
					</Link> 
				}
				{/* <h1>{Search}</h1> */}
				{/*Navbar*/}
				{/* <ul className="navbar-nav ml-auto ml-md-0 osahan-right-navbar">
					<li className="nav-item mx-1">
						<Link to="/upload" className="nav-link">
							<FontAwesomeIcon icon={faPlusCircle} fixedWidth color="white" />{" "}
							<span className="d-none d-md-inline" style={{color: 'white'}} >
								Upload Magazine
							</span>
						</Link>
					</li>

					<NavDropdown
						title={
							<>
								<FontAwesomeIcon icon={faBell} fixedWidth color="white"/>
								<Badge variant="danger">9+</Badge>
							</>
						}
						id=""
						className="mx-1 no-arrow"
					>
						<NavDropdown.Item href="#">
							<FontAwesomeIcon icon={faEdit} fixedWidth/> Action
						</NavDropdown.Item>
						<NavDropdown.Item href="#">
							<FontAwesomeIcon
								icon={faHeadphonesAlt}
								fixedWidth
							/>{" "}
							Another Action
						</NavDropdown.Item>
						<Dropdown.Divider />
						<NavDropdown.Item href="#">
							<FontAwesomeIcon icon={faStar} fixedWidth />{" "}
							Something else here
						</NavDropdown.Item>
					</NavDropdown>

					<NavDropdown
						title={
							<>
								<FontAwesomeIcon icon={faEnvelope} fixedWidth color="white"/>
								<Badge variant="success">7</Badge>
							</>
						}
						id=""
						className="mx-1 no-arrow"
					>
						<NavDropdown.Item href="#">
							<FontAwesomeIcon icon={faEdit} fixedWidth /> Action
						</NavDropdown.Item>
						<NavDropdown.Item href="#">
							<FontAwesomeIcon
								icon={faHeadphonesAlt}
								fixedWidth
							/>{" "}
							Another Action
						</NavDropdown.Item>
						<Dropdown.Divider />
						<NavDropdown.Item href="#">
							<FontAwesomeIcon icon={faStar} fixedWidth />{" "}
							Something else here
						</NavDropdown.Item>
					</NavDropdown>

					<NavDropdown
						title={
							<>
								<img src="/img/user.png" alt="Avatar" />
								<span className="d-none d-md-inline" style={{color:'white'}}>
									{" "}
									Login
								</span>
							</>
						}
						id=""
						className="no-arrow osahan-right-navbar-user user-dropdown-link"
					>
						<Link
							to="/account"
							className="dropdown-item"
							rolw="button"
						>
							<FontAwesomeIcon icon={faUserCircle} fixedWidth />{" "}
							My Account
						</Link>
						<Link
							to="/subscriptions"
							className="dropdown-item"
							rolw="button"
						>
							<FontAwesomeIcon icon={faVideo} fixedWidth />{" "}
							Subscriptions
						</Link>
						<Link
							to="/settings"
							className="dropdown-item"
							rolw="button"
						>
							<FontAwesomeIcon icon={faCog} fixedWidth /> Settings
						</Link>
						<Dropdown.Divider />
						<Link
							to="#"
							onClick={handleShow}
							className="dropdown-item"
							rolw="button"
						>
							<FontAwesomeIcon icon={faSignOutAlt} fixedWidth />{" "}
							Log out
						</Link>
					</NavDropdown>
				</ul> */}
				{searchShow ? <div className="Search_wrapper">
					{
						SearchResult.slice(0, 10).map((item, i) => {
							return (
								<>
									<div key={i} className="d-flex align-items-center flex-wrap search" onClick={() => GotoBrandDetaile(item)}>
										<img src={item.imgsrc} style={{ width: "60px" }} />
										<p key={i} style={{ fontSize: "15px" }} className="searchresult_text">{item.title}</p>
									</div>

								</>
							)
						})
					}
				</div> : ""}
			</Navbar >

			<LogoutModal
				show={show}
				handleClose={handleClose}
				handleLogout={handleLogout}
			/>

		</>
	);
};

export default Navigation;
