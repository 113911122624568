import React, { useState } from "react";
import './FlipBook.css'
import { setSelectedBrand } from "../../Action/brandAction";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';



const FlipBook = ({ name, coverImageSrc, id, title }) => {
    const isNameLong = name.length > 15 ? true : false;
    const shortName = `${name.substring(0, 15)}...`;

    const [shownName, setShownName] = useState(isNameLong ? shortName : name)
    const history = useHistory()
    const dispatch = useDispatch()
    const coverbase = "https://api.basevik.com/static/"

    const onMouserOverEvent = (e) => {
        if (isNameLong) {
            setShownName(name)
        }
    }
    const onMouserLeaveEvent = (e) => {
        if (isNameLong) {
            setShownName(shortName)
        }
    }
    const GotoBrandDetaile = () => {
        localStorage.setItem("selectedBrandId", id)
        localStorage.setItem("slectedBrandname", title)
        dispatch(
            setSelectedBrand(title, id)
        ).then((res) => {
            // const URL =  Sname.replace(/\s+/g, '-')
            history.push('/brand/' + title.replace(/\s+/g, '-'));

        }).catch((err) => {
            console.log("redux ---", err);
        })
    };
    return (
        <>
            <div >
                <a onClick={() => GotoBrandDetaile()} className="grid__item">
                    <img
                        className="img-fluid custom-slick-img imgsize "
                        src={coverImageSrc}
                        style={{ borderRadius: "5px" }}
                    />
                    <div className={isNameLong ? 'scroll-left' : ''} style={{ marginTop: '12px' }}>
                        <h6 style={{ color: 'white', padding: 0, textAlign: 'center' }} onMouseOver={onMouserOverEvent} onMouseOut={onMouserLeaveEvent}>
                            {shownName}
                        </h6>
                    </div>
                </a>
            </div>
        </>
    )
}
export default FlipBook;





{/* <img style={style} ref={el => imgRef = el} src={coverImageSrc} /> */ }